var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "columns is-multiline" }, [
      _c("div", { staticClass: "column is-one-third" }, [
        _vm._v(" One Third Column ")
      ]),
      _c("div", { staticClass: "column is-two-thirds" }, [
        _vm._v(" Two Thirds Column ")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }