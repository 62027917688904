<template>
    <div class="columns is-multiline">
        <div class="column is-one-third">
            One Third Column
        </div>
        <div class="column is-two-thirds">
            Two Thirds Column
        </div>
    </div>
</template>

<script>
export default {
    name: 'Index',
    inject: ['route'],
    beforeMount() {
        this.$router.push({ name: 'locations.index' });
    },
};
</script>
